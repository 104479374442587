import React from 'react';
import ReactDOM from 'react-dom';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import App from './App';
import AppStateProvider, { useAppState } from './state';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { ConnectOptions } from 'twilio-video';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import LoginPage from './components/LoginPage/LoginPage';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import theme from './theme';
import './types';
import { VideoProvider } from './components/VideoProvider';

debugger;

(window as any).configSettings = {
  token: window.location.search.split('&')[0].split('=')[1],
  env: window.location.search.split('&')[1].split('=')[1],
  agantName: window.location.search.split('&')[3].split('=')[1],
  callLink: window.location.search.split('&')[4].split('=')[1],
};

(window as any).handleOnLeavePage = () => {
  debugger;
  const env = (window as any).configSettings.env;
  let url = '';
  switch (env) {
    case 'sandbox':
      url = 'http://localhost:3001/auth/end-call';
      break;
    case 'cellar':
      url = 'https://api.cellar.trulet.com/auth/end-call';
      break;
    case 'stage':
      url = 'https://api.stage.trulet.com/auth/end-call';
      break;
    case 'live':
      url = 'https://api.trulet.com/auth/end-call';
      break;
  }
  const endCallRequest = new XMLHttpRequest();
  endCallRequest.open('POST', url);
  endCallRequest.setRequestHeader('Content-Type', 'application/json');
  endCallRequest.setRequestHeader('current-version', '1.00');
  endCallRequest.setRequestHeader('client-type', 'console');
  endCallRequest.send(JSON.stringify({ callLink: (window as any).configSettings.callLink }));
  endCallRequest.onerror = err => {
    debugger;
    window.close();
  };
  endCallRequest.onload = err => {
    debugger;
    window.close();
  };
};

window.addEventListener('beforeunload', e => {
  e.preventDefault();
  (window as any).handleOnLeavePage();
});
const connectionOptions: ConnectOptions = {
  bandwidthProfile: {
    video: {
      mode: 'collaboration',
      renderDimensions: {
        high: { height: 1080, width: 1920 },
        standard: { height: 90, width: 160 },
        low: { height: 90, width: 160 },
      },
    },
  },
  dominantSpeaker: true,
  maxAudioBitrate: 12000,
  networkQuality: { local: 1, remote: 1 },
  preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
};

const VideoApp = () => {
  const { error, setError } = useAppState();

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <App />
    </VideoProvider>
  );
};

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
      <AppStateProvider>
        <Switch>
          <PrivateRoute exact path="/">
            <VideoApp />
          </PrivateRoute>
          <PrivateRoute path="/room/:URLRoomName">
            <VideoApp />
          </PrivateRoute>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Redirect to="/" />
        </Switch>
      </AppStateProvider>
    </Router>
  </MuiThemeProvider>,
  document.getElementById('root')
);
